/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { Component } from "react";
import PropTypes from "prop-types"
import { Container } from 'react-bootstrap';



import Header from "./header"
import Footer from "./footer"
import "../scss/global.scss"

class Layout extends Component {
  constructor(props){
    super(props);
    this.state={
      menu:false
    };
  }

  getState(stateK, stateV){
    this.setState({
      [`${stateK}`] : stateV
    })
  }

  render(){
    return (
      <Container fluid className={`p-0 theramex`}>
        <div className="row h-100 align-items-center justify-content-center mx-0">
          <div className="col-12 p-0">
            <Header 
              postState={this.getState.bind(this)}
              siteTitle={'THERAMEX - Actonel'}
            />
          </div>
          <div className="col-12 p-0">
            <main className="row mx-0 mx-lg-auto main">{this.props.children}</main>
          </div>
          <div className="col-12 p-0">
            <Footer />
          </div>
          <div className="circle circle-1"></div>
          <div className="circle circle-2"></div>
          <div className="circle circle-3"></div>
        </div>
      </Container>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
