import PropTypes from "prop-types"
import React, { Component } from "react";

//import{ Logo } from "./svg/index"

class Header extends Component {
  constructor(props){
    super(props);
    this.state={
      menu:false
    };
  }


  render(){

    return(
      <header className="row mx-0 justify-content-center align-items-center header">
          <h1 className="col-auto p-0 logo">
              {/* <Logo /> */}
              <img src="/logo.png" alt="Theramex" />
          </h1>
          
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
