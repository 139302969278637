import React, { Component } from "react";

class Footer extends Component {
  constructor(props){
    super(props);
    this.state={

    };
  }

  render(){

    return(
      <footer className="row mx-0 align-items-center justify-content-center footer">
        <p className="col-12 p-0 text-center">
            theramex-france@theramex.com<br />
            Theramex France, Tour Atlantique - 1 Place de la Pyramide<br />
            92911 Paris La Défense
        </p>
      </footer>
    )
  }
}

export default Footer
